<template>
  <!--关联讲师-->
  <div>
    <div class="wxq_selected">
      <span v-for="(item,index) in handleSelectionList" :key="item.teaId">
        {{ item.teaName }}
        <i class="el-icon-error" @click="getDelete(item)"></i></span>
    </div>
    <el-form>
      <div class="wxq_storeroom">
        <div class="wxq_screen">
          <el-input v-model="form.teaName" placeholder="请输入讲师姓名" clearable></el-input>
          <el-select
            clearable
            :disabled="form.creatorBy == 2"
            v-model="form.state"
            placeholder="请选择"
          >
            <el-option value="" label="全部状态"></el-option>
            <el-option
              v-for="item in lecturerState"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.creatorBy"
            placeholder="请选择"
            @change="handleClick"
          >
            <el-option :value="1" label="本人创建"></el-option>
            <el-option :value="2" label="他人创建"></el-option>
          </el-select>
          <el-button class="btn btn_blue" @click="search()">搜索</el-button>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            ref="multipleTable"
            :row-key="(row) => row.teaId"
            @select="handleSelectionChange"
            style="width: 100%"
          >
            <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
            <el-table-column prop="teaName" label="讲师姓名" width="200" show-overflow-tooltip
                             align="center"></el-table-column>
            <el-table-column prop="courseNum" label="关联课程" width="110" align="center"></el-table-column>
            <el-table-column prop="state" label="状态" align="center">
              <template slot-scope="scope">
                 <span
                   class="gl_state"
                   :class="{
                      color_orange: scope.row.state == '审核中',
                      color_green: scope.row.state == '已发布',
                      color_red: scope.row.state == '已驳回',
                      color_gray: scope.row.state == '已下架' || '未发布',
                    }">
                   {{ scope.row.state }}
                 </span>
              </template>
            </el-table-column>
            <el-table-column prop="creatorName" label="创建人" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="修改时间" width="200" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="operate color_blue" @click.stop="detail(scope.row.teaId)">预览</span>
              </template>
            </el-table-column>
          </el-table>
          <pagination v-if="totalCount > 0" :pageSize="form.pageSize" :total="totalCount"
                      @pageChange="pageVal"></pagination>
        </div>
      </div>
      <div class="dialog_submit">
        <button class="btn btn_blue" @click="onsubmit()">提交</button>
      </div>
    </el-form>
    <el-dialog
      class="dialog_wrapper"
      title="讲师详情"
      v-if="isDetail"
      :visible.sync="isDetail"
      :modal-append-to-body="true"
      append-to-body
      top="15vh"
      width="650px"
    >
      <lecturer-detail :id="teaId"></lecturer-detail>
    </el-dialog>
  </div>
</template>

<script>
import lecturerDetail from "@views/backStageEnd/lecturer/components/lecturerDetail";
import pagination from '@/components/backStageComponent/pagination';
import API from "@apis/backStageEndAPI/lecturerAPI";
import CommonUtils from "@utils";
export default {
  name: "relatedLecturers",
  props: ['id', 'dataList'],
  components: {
    pagination,
    lecturerDetail
  },
  data() {
    return {
      form: {
        creatorBy: 1,
        page: 1,
        pageSize: 10,
        teaName: "",
        state: '',
      },
      lecturerState: [
        {id: 0, label: '未发布'},
        {id: 1, label: "已发布"},
        {id: 2, label: "审核中"},
        {id: 3, label: "已驳回"},
        {id: 9, label: "已下架"},
      ],
      submit: [],
      totalCount: 0,
      tableData: [],
      handleSelectionList: [],
      isDetail: false,
      teaId: '',
    };
  },
  mounted() {
    this.handleSelectionList = this.dataList
    this.getTableList()
  },
  methods: {
    getTableList(type) { //获取讲师列表
      API.getTeacherList(CommonUtils.parseToParams(this.form)).then(res => {
        res.dataList.map((item) => {
          switch (item.state) {
            case 0:
              item.state = "未发布";
              break;
            case 1:
              item.state = "已发布";
              break;
            case 2:
              item.state = "审核中";
              break;
            case 3:
              item.state = "已驳回";
              break;
            case 9:
              item.state = "已下架";
          }
        });
        this.tableData = res.dataList
        this.submit = []
        if (this.dataList && this.dataList.length) {
          this.$nextTick(() => {
            this.tableData.forEach((item) => {
              this.dataList.forEach((i) => {
                if (item.teaId == i.teaId) {
                  this.$refs.multipleTable.toggleRowSelection(item, true);
                }
              });
            });
          });
          this.dataList.forEach((i) => {
            this.submit.push({
              courseId: this.id,
              teaId: i.teaId,
            })
          });
        }
        if (type) {
          this.$refs.multipleTable.clearSelection()
          this.$nextTick(() => {
            this.tableData.forEach((item) => {
              this.handleSelectionList.forEach((i) => {
                if (item.teaId == i.teaId) {
                  this.$refs.multipleTable.toggleRowSelection(item, true);
                }
              });
            });
          });
        }
        this.totalCount = res.rowCount
      })
    },
    detail(id) {
      this.teaId = id;
      this.isDetail = true;
    },
    getDelete(val) {
      this.handleSelectionList.forEach((item, index) => {
        if (val.teaId == item.teaId) {
          this.handleSelectionList.splice(index, 1)
          this.tableData.forEach((i, inx) => {
            if (i.teaId == item.teaId) {
              this.$refs.multipleTable.toggleRowSelection(this.tableData[inx], false);
            }
          })
        }
      })
      this.submit.forEach((i, inx) => {
        if (i.teaId == val.teaId) {
          this.submit.splice(inx, 1)
        }
      })
    },
    search() { //搜索
      this.form.page = 1
      this.getTableList(true)
    },
    onsubmit() { //提交
      // if (Object.keys(this.submit).length < 1) {
      //   this.$message.warning('请选择讲师后再提交');
      // } else {
      this.submit.forEach((item, index) => {
        item.teaSort = index
      })
      // this.submit = []
      API.getRelevancyTeacher(this.submit, this.id).then(res => {
        if (res && !res.status) {
          this.$emit('changeLecturerClose');
        }
      })
      // }
    },
    clickRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
      this.handleSelectionChange(this.handleSelectionList, row);
    },
    handleSelectionChange(selection, row) {
      let selected = selection.length && selection.indexOf(row) !== -1
      if (selected) {
        this.handleSelectionList.push(selection[selection.length - 1]);
        selection.forEach((item, index) => {
          if (item.teaId == row.teaId) {
            this.submit.push({
              courseId: this.id,
              teaId: item.teaId,
            })
          }
        })
      } else {
        this.submit.forEach((i, inx) => {
          if (i.teaId == row.teaId) {
            this.submit.splice(inx, 1)
          }
        })
        this.handleSelectionList.forEach((i, inx) => {
          if (i.teaId == row.teaId) {
            this.handleSelectionList.splice(inx, 1)
          }
        })
      }
    },
    handleClick(val) {
      if (val == 1) {
        this.form.state = ''
      } else if (val == 2) {
        this.form.state = 1
      }
    },
    pageVal(val) {
      //分页跳转
      this.form.page = val;
      this.getTableList()
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/backStageEndCSS/myCoursePageMenu';
.wxq_selected {
  padding: 20px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  span {
    background: #F4F9FD;
    border-radius: 20px;
    padding: 10px 14px;
    margin: 0 18px 8px 0;
    i {
      font-size: 18px;
      vertical-align: text-top;
      cursor: pointer;
    }
    i:hover {
      color: #F65160;
    }
  }
}
</style>
