<template>
  <!--问卷调查-->
  <div>
    <div class="wxq_container">
      <el-button v-if="data.state == 9 || data.state == 3 || data.state == 0" icon="el-icon-plus" class="btn btn_orange wxq_add" :disabled="isDisabled" @click="getAddExam">添加问卷
      </el-button>
      <div class="wxq_exam" v-if="Object.keys(formData).length > 0">
        <div class="wxq_exam_time">
          <p>更新时间：<span>{{ formData.updateTime }}</span></p>
          <div class="wxq_exam_state bg_lightGreen">{{ QUESTIONAIRE_STATUS.get(formData.state) }}</div>
        </div>
        <div class="wxq_exam_detail">
          <p class="wxq_exam_detail_title">{{ formData.title }}</p>
          <div class="wxq_sum_limit" v-if="data.state == 9 || data.state == 3 || data.state == 0">
            <i class="el-icon-delete" @click="getDelete"></i>
          </div>
        </div>
      </div>
      <el-dialog
        class="dialog_wrapper"
        title="添加问卷"
        v-if="isAddSurveys"
        :visible.sync="isAddSurveys"
        :modal-append-to-body="false"
        top="10vh"
        width="914px">
        <add-surveys :id="data.courseId" @changeSurveysClose="getCloseDialog"></add-surveys>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import addSurveys from "./addSurveys";
import moment from 'moment'
import API from "@/apis/backStageEndAPI/myCourseAPI";
import {QUESTIONAIRE_STATUS} from '@/utils/enum.js'
import {fetchQuestionaireListById} from '@/apis/backStageEndAPI/examStageManagement/index.js'
export default {
  name: "surveys",
  props: ['data'],
  components: {
    addSurveys
  },
  data() {
    return {
      moment,
      QUESTIONAIRE_STATUS,
      isDisabled: true,//禁用按钮
      isAddSurveys: false,//弹框开关
      formData: {
        startTime: '',
      }
    }
  },
  mounted() {
    if (this.data.surveyId != 0) {
      this.getExamDetail(this.data.surveyId)
    } else {
      this.formData = {}
      this.isDisabled = false
    }
  },
  methods: {
    getExamDetail(surveyId) {
      fetchQuestionaireListById({surveyId: surveyId}).then(res => {
        this.formData = res.survey
      })
    },
    getAddExam() {//添加考试
      this.isAddSurveys = true
    },
    getCloseDialog(val) {//关闭弹框
      this.isAddSurveys = false
      this.getExamDetail(val.surveyId)
      this.isDisabled = true
    },
    getDelete() { //删除
      API.getDeleteSurvey(this.data.courseId, this.data.surveyId).then(res => {
        this.formData = {}
        this.isDisabled = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wxq_container {
  .wxq_add {
    margin: 0 0 20px 10px;
  }
  .is-disabled {
    background: #ced5e0;
  }
  .wxq_exam {
    width: 430px;
    height: 159px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    background: #fff;
    .wxq_exam_time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      height: 42px;
      background: #f7f7f7;
      .wxq_exam_state {
        width: 54px;
        height: 24px;
        border-radius: 100px 0 0 100px;
        color: #fff;
        text-align: center;
        line-height: 24px;
      }
    }
    .wxq_exam_detail {
      padding: 10px 15px 15px;
      color: #91929e;
      p {
        line-height: 30px;
      }
      .wxq_exam_detail_title {
        color: #333;
        font-weight: 500;
        font-size: 20px;
        padding: 10px 0 18px 0;
      }
      .wxq_sum_limit {
        text-align: right;
        .el-icon-delete {
          font-size: 26px;
          cursor: pointer;
        }
        .el-icon-delete:before {
          color: #f65160;
        }
      }
    }
  }
}
</style>
