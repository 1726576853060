import { render, staticRenderFns } from "./addVideo.vue?vue&type=template&id=cf6b1058&scoped=true"
import script from "./addVideo.vue?vue&type=script&lang=js"
export * from "./addVideo.vue?vue&type=script&lang=js"
import style0 from "./addVideo.vue?vue&type=style&index=0&id=cf6b1058&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf6b1058",
  null
  
)

export default component.exports