<template>
  <!--随堂考试-->
  <div class="wxq_container">
    <el-button v-if="data.state == 9 || data.state == 3 || data.state == 0" icon="el-icon-plus" class="btn btn_orange wxq_add" :disabled="isDisabled" @click="getAddExam">添加考试
    </el-button>
    <div class="wxq_exam" v-if="Object.keys(formData).length > 0">
      <div class="wxq_exam_time">
        <p>考试时间：<span>{{ formData.startTime }} ~ {{ formData.endTime }}</span></p>
        <div class="wxq_exam_state bg_lightGreen">{{ EXAM_STATUS.get(formData.state) }}</div>
      </div>
      <div class="wxq_exam_detail">
        <p class="wxq_exam_detail_title">{{ formData.title }}</p>
        <p class="wxq_exam_detail_author">
          <span>{{ formData.userName }}&emsp;创建于&nbsp;{{ moment(formData.createTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
          <span>{{ formData.times }}人已参与</span>
        </p>
        <div class="wxq_sum_limit">
          <p>
            <span class="wxq_exam_sum color_blue">共{{ formData.quCount }}题</span>
            <span>限时{{ formData.totalTime }}分钟</span>
            <span>满分{{ formData.totalScore }}分</span>
          </p>
          <i v-if="data.state == 9 || data.state == 3 || data.state == 0" class="el-icon-delete" @click="getDelete"></i>
        </div>
      </div>
    </div>
    <el-dialog
      class="dialog_wrapper"
      title="添加考试"
      v-if="isAddExam"
      :visible.sync="isAddExam"
      :modal-append-to-body="false"
      top="10vh"
      width="914px">
      <add-the-test :id="data.courseId" @changeTestClose="getCloseDialog"></add-the-test>
    </el-dialog>
  </div>
</template>

<script>
import addTheTest from "./addTheTest";
import moment from 'moment'
import API from "@/apis/backStageEndAPI/myCourseAPI";
import {EXAM_STATUS} from '@/utils/enum.js'
import {fetchExamDetail} from '@/apis/backStageEndAPI/examStageManagement/index.js'
export default {
  name: "quizzes",
  props: ['data'],
  components: {
    addTheTest
  },
  data() {
    return {
      moment,
      EXAM_STATUS,
      isDisabled: true,//禁用按钮
      isAddExam: false,//弹框开关
      formData: {
        startTime: '',
      }
    }
  },
  mounted() {
    if (this.data.examId != 0) {
      this.getExamDetail(this.data.examId)
    } else {
      this.formData = {}
      this.isDisabled = false
    }
  },
  methods: {
    getExamDetail(examId) {
      fetchExamDetail({id: examId}).then(res => {
        this.formData = res.data
      })
    },
    getAddExam() {//添加考试
      this.isAddExam = true
    },
    getCloseDialog(val) {//关闭弹框
      this.isAddExam = false
      this.getExamDetail(val.id)
      this.isDisabled = true
    },
    getDelete() { //删除
      API.getDeleteExam(this.data.courseId, this.data.examId).then(res => {
        this.formData = {}
        this.isDisabled = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wxq_container {
	.wxq_add {
		margin: 0 0 20px 10px;
	}
	.is-disabled {
		background: #ced5e0;
	}
	.wxq_exam {
		width: 430px;
		height: 159px;
		border: 1px solid #e6e6e6;
		border-radius: 3px;
		background: #fff;
		.wxq_exam_time {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 15px;
			height: 42px;
			background: #f7f7f7;
			.wxq_exam_state {
				width: 54px;
				height: 24px;
				border-radius: 100px 0 0 100px;
				color: #fff;
				text-align: center;
				line-height: 24px;
			}
		}
		.wxq_exam_detail {
			padding: 10px 15px 15px;
			color: #91929e;
			p {
				line-height: 30px;
			}
			.wxq_exam_detail_title {
				color: #333;
				font-weight: 500;
				font-size: 16px;
			}
			.wxq_exam_detail_author {
				display: flex;
				justify-content: space-between;
			}
			.wxq_sum_limit {
				display: flex;
				align-items: center;
				justify-content: space-between;
				span {
					margin-right: 10px;
				}
				.wxq_exam_sum {
					padding: 4px 12px;
					border-radius: 12px;
					background: #e8f1ff;
				}
				.el-icon-delete {
					font-size: 26px;
					cursor: pointer;
				}
				.el-icon-delete:before {
					color: #f65160;
				}
			}
		}
	}
}
</style>
