<template>
  <!--添加考试-->
  <div>
    <el-form>
      <div class="wxq_storeroom">
        <div class="wxq_screen">
          <el-select v-model="form.params.state" placeholder="考试状态" clearable>
            <el-option v-for="item in EXAM_STATUS" :key="item[0]" :label="item[1]"
                       :value="item[0]"></el-option>
          </el-select>
          <el-button class="btn btn_blue" @click="search()">搜索</el-button>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            @row-click="clickRow"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="title" label="考试名称" width="200" show-overflow-tooltip
                             align="center"></el-table-column>
            <el-table-column prop="typeName" label="考试类型" width="110" align="center"></el-table-column>
            <el-table-column prop="address" label="考试时间" align="center">
              <template slot-scope="scope">
              <span v-if="scope.row.timeLimit">
                {{ scope.row.startTime }} ~
                {{ scope.row.endTime }}
              </span>
                <span v-else>不限时</span>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="考试状态" width="110" align="center">
              <template slot-scope="scope">{{ EXAM_STATUS.get(scope.row.state) }}</template>
            </el-table-column>
          </el-table>
          <pagination v-if="totalCount > 0" :pageSize="formData.pageSize" :total="totalCount"
                      @pageChange="pageVal"></pagination>
        </div>
      </div>
      <div class="dialog_submit">
        <button class="btn btn_blue" @click="onsubmit()">提交</button>
      </div>
    </el-form>
  </div>
</template>

<script>
import pagination from '@/components/backStageComponent/pagination';
import {fetchExamList} from '@/apis/backStageEndAPI/examStageManagement/index.js'
import {EXAM_STATUS} from '@/utils/enum.js'
import myCourseAPI from "@/apis/backStageEndAPI/myCourseAPI";
export default {
  name: 'addTheTest',
  props: ['id'],
  components: {
    pagination
  },
  data() {
    return {
      EXAM_STATUS,
      form: {
        current: 1,
        size: 10,
        params: {}
      },
      submit: {
        courseId: '',//课程ID
        examId: '',//课件ID
      },
      formData: {},
      totalCount: 0,
      tableData: []
    };
  },
  mounted() {
    this.submit.courseId = this.id
    this.getList()
  },
  methods: {
    async getList() { //获取考试列表
      const {data} = await fetchExamList(this.form)
      this.tableData = data?.records || []
      this.totalCount = data.total
    },
    search() { //搜索
      this.getList()
    },
    onsubmit() { //提交
      if (Object.keys(this.formData).length < 1) {
        this.$message.warning('请选择考试后再提交');
      } else {
        myCourseAPI.updateCourse(this.submit).then(res => {
          if (res && !res.status) {
            this.$emit('changeTestClose', this.formData);
          }
        })
      }
    },
    clickRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
      this.handleSelectionChange(this.handleSelectionList);
    },
    handleSelectionChange(selection) {
      this.handleSelectionList = selection;
      if (selection.length === 1) {
        this.formData = selection[0];
      } else if (selection.length > 1) {
        let del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      } else {
        this.formData = {};
      }
      this.submit.examId = selection[0].id
    },
    pageVal(val) {
      //分页跳转
      this.formData.pageIndex = val;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/backStageEndCSS/myCoursePageMenu';
</style>
