<template>
  <!--新建菜单里的添加视频-->
  <div class="wxq_container">
    <div class="wxq_storeroom">
      <div class="wxq_screen">
        <el-date-picker
          clearable
          v-model="form.startTime"
          type="datetime"
          placement="bottom-start"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择开始时间"
        ></el-date-picker>
        <el-date-picker
          clearable
          v-model="form.endTime"
          type="datetime"
          placement="bottom-start"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择结束时间"
        ></el-date-picker>
        <el-input
          placeholder="请输入关键字"
          prefix-icon="el-icon-search"
          v-model="form.keyword"
          maxlength="50"
          show-word-limit
        >
        </el-input>
        <el-button @click="search" class="btn btn_blue">搜索</el-button>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          @row-click="clickRow"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="fileName"
            label="视频名称"
            show-overflow-tooltip
            align="center"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="上传时间"
            show-overflow-tooltip
            align="center"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="fileExt"
            label="视频格式"
            show-overflow-tooltip
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="filePath"
            label="视频地址"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
        </el-table>
        <pagination
          v-if="totalCount > 0"
          :pageSize="form.pageSize"
          :total="totalCount"
          @pageChange="pageVal"
        ></pagination>
      </div>
    </div>
    <div class="dialog_submit">
      <button class="btn btn_blue" @click="onsubmit()">提交</button>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/backStageComponent/pagination";
import API from "@/apis/backStageEndAPI/coursewareManagementAPI";
import CommonUtils from "@/utils";
export default {
  name: "addVideo",
  props: ["videoData"],
  components: {
    pagination,
  },
  data() {
    return {
      form: {
        startTime: "", //开始时间
        endTime: "", //结束时间
        keyword: "", //关键字
        fileGroup: 2, //0|其他，1|课件文档库，2|课件视频库
        page: 1, //页码
        pageSize: 10, //分页大小
      },
      totalCount: 0,
      tableData: [],
      formData: [],
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    search() {
      this.form.page = 1;
      this.getTableList();
    },
    getTableList() {
      this.form.startTime == null
        ? (this.form.startTime = "")
        : this.form.startTime;
      this.form.endTime == null ? (this.form.endTime = "") : this.form.endTime;
      //获取视频列表
      API.getFileList(CommonUtils.parseToParams(this.form)).then((res) => {
        this.tableData = res.dataList;
        this.totalCount = res.rowCount;
        if (this.videoData) {
          this.$nextTick(() => {
            this.tableData.forEach((item) => {
              this.videoData.forEach((i) => {
                if (item.fileId == i.fileId) {
                  this.$refs.multipleTable.toggleRowSelection(item, true);
                }
              });
            });
          });
        }
      });
    },
    onsubmit() {
      if (this.formData.length == 0) {
        this.$message.error("请选择视频后再提交");
      } else {
        this.$emit("changeVideoDialogClose", this.formData);
      }
    },
    clickRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleSelectionChange(selection) {
      this.formData = selection;
    },
    pageVal(val) {
      //分页跳转
      this.form.page = val;
      this.getTableList();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/css/backStageEndCSS/myCoursePageMenu";
</style>
