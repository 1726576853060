<template>
  <!--添加问卷-->
  <div>
    <el-form>
      <div class="wxq_storeroom">
        <div class="wxq_screen">
          <el-input v-model="form.title" placeholder="请输入关键字" clearable></el-input>
          <el-button class="btn btn_blue" @click="search()">搜索</el-button>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            @row-click="clickRow"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="title" label="标题" width="200" show-overflow-tooltip
                             align="center"></el-table-column>
            <el-table-column prop="username" label="发布人" width="110" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
            <el-table-column prop="address" label="问卷状态" width="110" align="center">
              <template slot-scope="scope">{{ QUESTIONAIRE_STATUS.get(scope.row.state) }}</template>
            </el-table-column>
          </el-table>
          <pagination v-if="totalCount > 0" :pageSize="formData.pageSize" :total="totalCount"
                      @pageChange="pageVal"></pagination>
        </div>
      </div>
      <div class="dialog_submit">
        <button class="btn btn_blue" @click="onsubmit()">提交</button>
      </div>
    </el-form>
  </div>
</template>

<script>
import pagination from '@/components/backStageComponent/pagination';
import {questionSurvey} from '@/apis/backStageEndAPI/examStageManagement/index.js'
import {QUESTIONAIRE_STATUS} from '@/utils/enum.js'
import myCourseAPI from "@/apis/backStageEndAPI/myCourseAPI";
export default {
  name: "addSurveys",
  props: ['id'],
  components: {
    pagination
  },
  data() {
    return {
      QUESTIONAIRE_STATUS,
      form: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        state: 2,
      },
      submit: {
        courseId: '',//课程ID
        surveyId: '',//问卷ID
      },
      formData: {},
      totalCount: 0,
      tableData: []
    };
  },
  mounted() {
    this.submit.courseId = this.id
    this.getList()
  },
  methods: {
    async getList() { //获取问卷列表
      const data = await questionSurvey(this.form)
      this.tableData = data?.records || []
      this.totalCount = data.total
    },
    search() { //搜索
      this.getList()
    },
    onsubmit() { //提交
      if (Object.keys(this.formData).length < 1) {
        this.$message.warning('请选择考试后再提交');
      } else {
        myCourseAPI.updateCourse(this.submit).then(res => {
          if (res && !res.status) {
            this.$emit('changeSurveysClose', this.formData);
          }
        })
      }
    },
    clickRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
      this.handleSelectionChange(this.handleSelectionList);
    },
    handleSelectionChange(selection) {
      this.handleSelectionList = selection;
      if (selection.length === 1) {
        this.formData = selection[0];
      } else if (selection.length > 1) {
        let del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      } else {
        this.formData = {};
      }
      this.submit.surveyId = selection[0].surveyId
    },
    pageVal(val) {
      //分页跳转
      this.formData.pageIndex = val;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/backStageEndCSS/myCoursePageMenu';
</style>
