<template>
  <!--新建课程菜单-->
  <div class="wxq_container container">
    <el-form class="form" :model="formData" :rules="rules" ref="form" :inline="true">
      <div class="wxq_filter">
        <el-radio-group v-model="formData.menuType">
          <el-radio :label="1" :disabled="courseType == 2">聊天互动</el-radio>
          <el-radio :label="2">课程评论</el-radio>
          <el-radio :label="3">图文</el-radio>
          <el-radio :label="4">视频</el-radio>
        </el-radio-group>
      </div>
      <div class="wxq_storeroom" v-if="formData.menuType == 3 || formData.menuType == 4">
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="menuName">
              <el-input v-model="formData.menuName" placeholder="请输入菜单名称" maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="formData.menuType == '3'">
            <el-form-item class="wxq_editor" prop="richText">
              <vue-editor id="editor" useCustomImageHandler @image-added="shangchuan"
                          v-model="formData.richText"></vue-editor>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-else>
            <el-button icon="el-icon-plus" class="btn btn_orange wxq_add" @click="getAddVideo">添加视频</el-button>
            <div class="table">
              <el-table :data="tableData" ref="multipleTable" style="width: 100%">
                <el-table-column prop="fileName" label="视频名称" show-overflow-tooltip align="center"
                                 width="180"></el-table-column>
                <el-table-column prop="createTime" label="上传时间" show-overflow-tooltip align="center"
                                 width="100"></el-table-column>
                <el-table-column prop="fileExt" label="视频格式" show-overflow-tooltip align="center"
                                 width="80"></el-table-column>
                <el-table-column prop="filePath" label="视频地址" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column
                  align="center"
                  label="操作"
                  width="80">
                  <template slot-scope="scope">
                    <span class="operate color_red" @click="getDelete(scope.row.fileId)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
              <!--              <pagination v-if="totalCount > 0" :total="totalCount" @pageChange="pageVal"></pagination>-->
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="dialog_submit">
        <button class="btn btn_blue" @click="onsubmit('form')">提交</button>
      </div>
    </el-form>
    <el-dialog
      class="dialog_wrapper"
      title="添加视频"
      v-if="isAddVideo"
      :visible.sync="isAddVideo"
      :modal-append-to-body="true"
      append-to-body
      top="15vh"
      width="864px"
    >
      <add-video :videoData="videoData" @changeVideoDialogClose="getCloseDialog"></add-video>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/backStageComponent/pagination';
import addVideo from '@views/backStageEnd/myCourses/component/addVideo';
import {VueEditor} from 'vue2-editor';
import API from "@/apis/backStageEndAPI/myCourseAPI";
import uploadAPI from '@/apis/common';
import CommonUtils from "@/utils";
var COS = require('cos-js-sdk-v5');
var cos = new COS({
  getAuthorization: async function (options, callback) {
    // console.log(options); m
    // 异步获取临时密钥
    await uploadAPI.getCosToken().then(data => {
      if (!data.credentials) return console.error('credentials invalid');
      var credentials = data && data.credentials;
      this.resultData = data;
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime // 时间戳，单位秒，如：1580000900
      });
    });
  }
});
export default {
  name: 'addCourseMenu',
  props: ['courseId', 'menuData', 'courseType'],
  components: {
    VueEditor,
    addVideo,
    pagination
  },
  data() {
    return {
      Bucket: 'slyl-edu-static-1301295327',
      Region: 'ap-beijing',
      isAddVideo: false,
      formData: {
        menuType: 3,//课程菜单类型：1|聊天互动，2|课程评论，3|自定义图文，4|自定义视频
        menuName: '',//菜单名称
        richText: '',//	富文本
        fileIds: '',//视频文件id,多条逗号拼接
      },
      totalCount: 0,
      tableData: [],
      videoData: [],
      rules: {
        menuName: [{required: true, message: '请输入菜单名称', trigger: 'blur'}],
        richText: [{required: true, message: '请输入菜单内容', trigger: 'blur'}],
      }
    };
  },
  mounted() {
    if (this.menuData) {
      this.formData = this.menuData
      this.getVideoList(this.menuData.menuId)
    }
  },
  methods: {
    onsubmit(formName) { //提交
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.menuData) { //有id调编辑接口，无id调新建接口
            API.getEditCourseMenu(this.courseId, this.formData).then(res => {
              this.$emit('changeMenuDialogClose')
            })
          } else {
            if (this.formData.menuType == 1) {
              this.formData.menuName = '聊天互动'
            } else if (this.formData.menuType == 2) {
              this.formData.menuName = '课程评论'
            }
            API.getAddCourseMenu(this.courseId, this.formData).then(res => {
              this.$emit('changeMenuDialogClose')
            })
          }
        } else {
          return false;
        }
      });
    },
    getDelete(fileId) { //删除
      this.tableData.forEach((item, index) => {
        if (item.fileId == fileId) {
          this.tableData.splice(index, 1)
        }
      })
      let arr = this.formData.fileIds.split(',')
      arr.forEach((item, index) => {
        if (item == fileId) {
          arr.splice(index, 1)
        }
      })
      this.formData.fileIds = arr.join(',')
    },
    getVideoList(menuId) { //获取视频列表
      let params = {
        page: 1,
        pageSize: 10,
      }
      API.getVideoList(this.courseId, menuId, CommonUtils.parseToParams(params)).then(res => {
        this.tableData = res.dataList
        this.totalCount = res.rowCount
      })
    },
    getAddVideo() {//添加视频
      this.isAddVideo = true;
      this.videoData = this.tableData
    },
    getCloseDialog(val) { //选择视频后回显并赋值
      this.isAddVideo = false;
      this.tableData = this.tableData == null ? [] : this.tableData
      if (this.tableData.length != '') {
        //如果列表有值则push
        let fileId = ''
        val.forEach((item) => {
          this.tableData.push(item)
          fileId += item.fileId + ','
          this.formData.fileIds = fileId
        });
        // let set = new Set(this.formData.fileIds.split(','))
        // this.formData.fileIds = [...set].join(',')//fileIds拼接并去重
        this.tableData = this.unique(this.tableData)//表格数据去重
      } else {
        //列表为空则直接赋值
        this.tableData = val
        val.forEach((item) => {
          this.formData.fileIds += item.fileId + ','
        });
      }
      this.formData.fileIds = this.formData.fileIds.substring(0, this.formData.fileIds.lastIndexOf(','))
      this.totalCount = this.tableData.length
    },
    unique(arr) { //去重方法
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.fileId) && res.set(arr.fileId, 1))
    },
    pageVal(val) {
      //分页跳转
      this.formData.pageIndex = val;
    },
    shangchuan(e, Editor, cursorLocation, resetUploader) {
      var _this = this;
      var file = e;
      if (!file) return;
      cos.putObject({ //上传对象
        Bucket: this.Bucket, //存储桶
        Region: this.Region, //存储桶所在地域
        StorageClass: 'STANDARD',
        Key: '/upload/images/' + file.name,
        Body: file,
      }, function (err, data) {
        // _this.progressVisible = false;
        cos.getObjectUrl({ //获取不带签名的对象的 Url
          Bucket: _this.Bucket,
          Region: _this.Region,
          Key: '/upload/images/' + file.name,
          Sign: false
        }, function (err, data) {
          Editor.insertEmbed(cursorLocation, "image", data.Url);
          resetUploader()
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/backStageEndCSS/myCoursePageMenu';
.wxq_storeroom {
  position: relative;
  .wxq_add {
    position: absolute;
    top: 0;
    right: 36px;
  }
  ::v-deep .el-pagination__editor {
    width: 50px !important;
  }
}
.wxq_editor {
  margin-bottom: 0;
  ::v-deep .el-form-item__content {
    padding-left: 0;
  }
}
</style>
