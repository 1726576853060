<template>
  <!--课件文档-->
  <div class="container">
    <el-button v-if="data.state == 9 || data.state == 3 || data.state == 0" icon="el-icon-plus" class="btn btn_orange wxq_add" :disabled="isDisabled " @click="getAddDocument">添加文档
    </el-button>
    <div class="hh_video_wrap" v-if="Object.keys(formData).length > 0">
      <div class="hh_video">
        <div class="pageImg">
          <img v-if="formData.fileExt == 'pdf'" src="@/assets/img/backStageImg/myCourse/pdfLogo.png" alt="" />
          <img v-if="formData.fileExt == 'pptx'|| formData.fileExt == 'ppt'" src="@/assets/img/backStageImg/myCourse/pptLogo.png" alt="" />
          <img v-if="formData.fileExt == 'doc'|| formData.fileExt == 'word' || formData.fileExt == 'msword'|| formData.fileExt == 'docx'" src="@/assets/img/backStageImg/myCourse/docLogo.png" alt="" />
        </div>
        <p class="hh_detail_title">{{ formData.fileName }}</p>
        <div class="hh_txt_wrap">
          <div class="hh_txt">
            <p>上传时间&emsp;{{ formData.createTime }}</p>
            <p>文件大小&emsp;{{ formData.fileSize }}</p>
          </div>
          <i v-if="data.state == 9 || data.state == 3 || data.state == 0" class="el-icon-delete" @click="getDelete"></i>
        </div>
      </div>
    </div>
    <el-dialog
      class="dialog_wrapper"
      title="添加文档"
      v-if="isAddDocument"
      :visible.sync="isAddDocument"
      :modal-append-to-body="false"
      top="10vh"
      width="914px">
      <add-the-document :id="data.courseId" @changeDocumentDialogClose="getCloseDialog"></add-the-document>
    </el-dialog>
  </div>
</template>

<script>
import addTheDocument from "./addTheDocument";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import coursewareAPI from "@/apis/backStageEndAPI/coursewareManagementAPI";
export default {
  name: "coursewareDocument",
  props: ['data'],
  components: {
    addTheDocument
  },
  data() {
    return {
      isDisabled: true,//禁用按钮
      isAddDocument: false,//弹框开关
      totalCount: 100,
      formData: {
        fileName: '',
        createTime: '',
        fileSize: '',
        fileExt: '',
      },
      submit: {
        courseId: this.data.courseId,
        fileId: '',
      }
    }
  },
  mounted() {
    if (this.data.fileId != 0) {
      this.getFileDetail(this.data.fileId)
    } else {
      this.formData = {}
      this.isDisabled = false
    }
  },
  methods: {
    getFileDetail(fileId) {
      coursewareAPI.fileDetail(fileId).then(res => {
          this.formData.fileName = res.fileName
          this.formData.createTime = res.createTime
          this.formData.fileSize = res.fileSize
          this.formData.fileExt = res.fileExt
      })
    },
    getAddDocument() {//添加文档
      this.isAddDocument = true
    },
    getCloseDialog(val) {//关闭弹框
      this.isAddDocument = false
      this.formData = val
      this.isDisabled = true
    },
    getDelete() { //删除
      API.getDeleteFile(this.data.courseId, this.data.fileId).then(res => {
        this.formData = {}
        this.isDisabled = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/css/backStageEndCSS/myCourseware";
.container {
  .is-disabled {
    background: #CED5E0;
  }
  .pageImg {
    background: #e8f1ff;
    text-align: center;
    line-height: 285px;
    margin-bottom: 4px;
    img {
      width: 77px;
      height: 86px;
    }
  }
  .el-icon-delete {
    font-size: 26px;
    margin-right: 10px;
    cursor: pointer;
  }
  .el-icon-delete:before {
    color: #F65160;
  }
}
</style>
