import { render, staticRenderFns } from "./relatedLecturers.vue?vue&type=template&id=49d67a1c&scoped=true"
import script from "./relatedLecturers.vue?vue&type=script&lang=js"
export * from "./relatedLecturers.vue?vue&type=script&lang=js"
import style0 from "./relatedLecturers.vue?vue&type=style&index=0&id=49d67a1c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d67a1c",
  null
  
)

export default component.exports