<template>
  <div class="container">
    <header-bar></header-bar>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/myCourses' }">
        <i class="el-icon-back"></i>
        返回
      </el-breadcrumb-item>
      <el-breadcrumb-item>页面菜单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wxq_title">
      <h1>{{ data.courseTitle }}</h1>
      <el-button
        icon="el-icon-view"
        class="btn btn_light_green"
        @click="getPreview()"
        >预览</el-button
      >
    </div>
    <div class="tabs">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="课程菜单" name="first">
          <course-menu v-if="data" :data="data"></course-menu>
        </el-tab-pane>
        <el-tab-pane
          label="课件文档"
          name="second"
          :disabled="data.courseType == 2"
        >
          <courseware-document
            v-if="detail"
            :data="detail"
          ></courseware-document>
        </el-tab-pane>
        <el-tab-pane label="签到签退" name="third">
          <sign-in-and-out v-if="detail" :data="detail"></sign-in-and-out>
        </el-tab-pane>
        <el-tab-pane label="随堂考试" name="fourth">
          <quizzes v-if="detail" :data="detail"></quizzes>
        </el-tab-pane>
        <el-tab-pane label="调查问卷" name="fifth">
          <surveys v-if="detail" :data="detail"></surveys>
        </el-tab-pane>
        <el-tab-pane label="讲师" name="sixth">
          <lecturer v-if="detail" :data="detail"></lecturer>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import courseMenu from "./component/courseMenu";
import coursewareDocument from "./component/coursewareDocument";
import signInAndOut from "./component/signInAndOut";
import quizzes from "./component/quizzes";
import surveys from "./component/surveys";
import lecturer from "./component/lecturer";
import API from "@/apis/backStageEndAPI/myCourseAPI";
export default {
  name: "pageMenu",
  components: {
    headerBar,
    courseMenu,
    coursewareDocument,
    signInAndOut,
    quizzes,
    surveys,
    lecturer,
  },
  data() {
    return {
      data: "",
      title: "2020年第三届中国医师节系列活动",
      activeName: "first",
      detail: "",
    };
  },
  mounted() {
    this.data = JSON.parse(this.$route.query.data);
    this.getDetail();
    this.tabLocal();
  },
  methods: {
    getPreview() {
      if (this.data.courseType == 1) {
        this.$router.push({
          path: "liveDetail",
          query: {
            id: this.data.courseId,
            type: "preview",
          },
        });
      } else if (this.data.courseType == 2) {
        this.$router.push({
          path: "dibbleDetail",
          query: {
            id: this.data.courseId,
            type: "preview",
          },
        });
      }
    },
    getDetail() {
      API.getCourseDetail(this.data.courseId).then((res) => {
        this.detail = res;
      });
    },
    tabLocal() {
      //获取本地tab保证刷新页面不返回第一个tab
      if (sessionStorage.getItem("task")) {
        this.activeName = sessionStorage.getItem("task");
      }
    },
    handleClick(tab) {
      //点击tab存储在本地
      sessionStorage.setItem("task", tab.name);
    },
  },
  destroyed() {
    sessionStorage.removeItem("task");
  },
};
</script>

<style scoped lang="scss">
.container {
  .wxq_header {
    margin-bottom: 0;
  }
  .wxq_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      margin: 16px 0 16px 6px;
      color: #0a1629;
      font-weight: 800;
      font-size: 24px;
    }
  }
  .el-breadcrumb__item {
    font-size: 16px;
    ::v-deep .is-link {
      color: var(--colorBlue);
      .el-icon-back {
        margin-right: 12px;
      }
    }
  }
}
</style>
