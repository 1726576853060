<template>
  <!--讲师-->
  <div>
    <div class="wxq_container">
      <el-button v-if="data.state == 9 || data.state == 3 || data.state == 0"
                 icon="el-icon-plus"
                 class="btn btn_orange wxq_add"
                 @click="getAddExam">
        关联讲师
      </el-button>
      <lecturer-main
        v-if="dataList.length > 0"
        :dataList="dataList"
        :pageSize="formData.pageSize"
        :type="'viewDetail'"
      ></lecturer-main>
      <el-dialog
        class="dialog_wrapper"
        title="关联讲师"
        v-if="isRelated"
        :visible.sync="isRelated"
        :modal-append-to-body="false"
        top="10vh"
        width="1100px">
        <related-lecturers :id="data.courseId" :dataList="dataList" @changeLecturerClose="getCloseDialog"></related-lecturers>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import lecturerMain from "@views/backStageEnd/lecturer/components/lecturerMain";
import relatedLecturers from "./relatedLecturers";
import API from "@apis/backStageEndAPI/lecturerAPI";
import CommonUtils from "@utils";
export default {
  name: "lecturer",
  props: ['data'],
  components: {
    lecturerMain,
    relatedLecturers,
  },
  data() {
    return {
      isRelated: false,//弹框开关
      formData: {
        page: 1,
        pageSize: 10,
        courseId: '',
      },
      dataList: []
    }
  },
  mounted() {
    if (this.data.courseId) {
      this.formData.courseId = this.data.courseId
      this.getList(this.formData)
    }
  },
  methods: {
    getList(val) {
      API.getRelevancyTeacherList(CommonUtils.parseToParams(val)).then(res => {
        res.map((item) => {
          switch (item.state) {
            case 0:
              item.state = "未发布";
              break;
            case 1:
              item.state = "已发布";
              break;
            case 2:
              item.state = "审核中";
              break;
            case 3:
              item.state = "已驳回";
              break;
            case 9:
              item.state = "已下架";
          }
        });
        this.dataList = res;
      })
    },
    getAddExam() {//关联讲师
      this.isRelated = true
    },
    getCloseDialog(val) {//关闭弹框
      this.isRelated = false
      this.getList(this.formData)
    },
    getDelete() { //删除
      API.getDeleteSurvey(this.data.courseId, this.data.surveyId).then(res => {
        this.formData = {}
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__header-wrapper .el-checkbox {
  // display: none;//设置不成功，页面卡顿
  visibility: hidden;
}
.wxq_container {
  .wxq_add {
    margin: 0 0 20px 10px;
  }
  .is-disabled {
    background: #ced5e0;
  }
  .wxq_exam {
    width: 430px;
    height: 159px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    background: #fff;
    .wxq_exam_time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      height: 42px;
      background: #f7f7f7;
      .wxq_exam_state {
        width: 54px;
        height: 24px;
        border-radius: 100px 0 0 100px;
        color: #fff;
        text-align: center;
        line-height: 24px;
      }
    }
    .wxq_exam_detail {
      padding: 10px 15px 15px;
      color: #91929e;
      p {
        line-height: 30px;
      }
      .wxq_exam_detail_title {
        color: #333;
        font-weight: 500;
        font-size: 20px;
        padding: 10px 0 18px 0;
      }
      .wxq_sum_limit {
        text-align: right;
        .el-icon-delete {
          font-size: 26px;
          cursor: pointer;
        }
        .el-icon-delete:before {
          color: #f65160;
        }
      }
    }
  }
}
</style>
