<template>
  <!--课程菜单-->
  <div class="table">
    <el-button icon="el-icon-plus" class="btn btn_orange wxq_add" @click="getAddMenu" v-if="data.state == '已下架' || data.state == '已驳回' || data.state == '未发布'">新建菜单</el-button>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="menuName"
        label="菜单名称"
        align="center"
        width="200">
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="name"-->
      <!--        label="显示 | 隐藏"-->
      <!--        align="center"-->
      <!--        width="200">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-switch-->
      <!--            v-model="scope.row.name"-->
      <!--            active-color="#3F8CFF"-->
      <!--            inactive-color="#CED5E0">-->
      <!--          </el-switch>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        align="center"
        label="操作"
        width="300">
        <template slot-scope="scope" v-if="data.state == '已下架' || data.state == '已驳回' || data.state == '未发布'">
          <!-- <span class="operate color_blue" @click="getMoveUp(scope.row)">上移</span>
          <span class="operate color_blue" @click="getMoveDown(scope.row)">下移</span> -->
          <span  class="operate color_blue" v-if="scope.row.menuType != 1 && scope.row.menuType != 2" @click="getCompile(scope.row)">编辑</span>
          <span class="operate color_blue" @click="getDelete(scope.row.menuId)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="dialog_wrapper"
      :title="addMenuTitle"
      v-if="isAddMenu"
      :visible.sync="isAddMenu"
      top="10vh"
      width="914px">
      <add-course-menu :courseId="data.courseId" :menuData="menuData" :courseType="data.courseType" @changeMenuDialogClose="getCloseDialog"></add-course-menu>
    </el-dialog>
  </div>
</template>

<script>
import addCourseMenu from "./addCourseMenu";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import CommonUtils from "@/utils";
export default {
  name: "courseMenu",
  props: ['data'],
  components: {
    addCourseMenu
  },
  data() {
    return {
      isAddMenu: false,
      addMenuTitle: '新建菜单',
      menuData: '',
      tableData: [{
        date: '聊天互动',
        name: false,
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '课程评论',
        name: true,
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '直播介绍',
        name: true,
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '直播回放',
        name: true,
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    }
  },
  mounted() {
    this.getMenuList()
  },
  methods: {
    getMenuList() { //获取菜单列表
      API.getCourseMenuList(this.data.courseId).then(res => {
        this.tableData = res
      })
    },
    getAddMenu() { //新建菜单
      this.addMenuTitle = '新建菜单'
      this.isAddMenu = true
      this.menuData = ''
    },
    getCloseDialog() { //关闭新建弹框
      this.isAddMenu = false
      this.getMenuList()
    },
    // getMoveUp() { //上移
    // },
    // getMoveDown() { //下移
    // },
    getCompile(row) { //编辑
      this.addMenuTitle = '编辑菜单'
      this.isAddMenu = true
      this.menuData = row
    },
    getDelete(menuId) { //删除
      API.getDeleteCourseMenu(this.data.courseId, menuId).then(res => {
        this.getMenuList()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.table {
  .wxq_add {
    margin: 0 0 20px 10px;
  }
}
</style>
