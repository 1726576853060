<template>
  <!--页面菜单-签到签退-->
  <div>
    <div class="wxq_table">
      <span>签到</span>
      <el-switch v-if="data.state == 9 || data.state == 3 || data.state == 0"
        v-model="formData.signInState"
        active-color="#3F8CFF"
        @change="changeSignIn"
        inactive-color="#CED5E0">
      </el-switch>
      <div>
        <el-date-picker
          v-model="formData.signInStartTime"
          type="datetime"
          placement="bottom-start"
          :disabled="isSignDisable"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择开始时间">
        </el-date-picker>
        <el-date-picker
          v-model="formData.signInEndTime"
          :disabled="isSignDisable"
          type="datetime"
          placement="bottom-start"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择结束时间">
        </el-date-picker>
        <!-- :picker-options="signInEndTimeOption" -->
      </div>
    </div>
    <div class="wxq_table">
      <span>签退</span>
      <el-switch v-if="data.state == 9 || data.state == 3 || data.state == 0"
        v-model="formData.signOutState"
        active-color="#3F8CFF"
        @change="changeSignOut"
        inactive-color="#CED5E0">
      </el-switch>
      <div>
        <el-date-picker
          v-model="formData.signOutStartTime"
          :disabled="isOutDisable"
          type="datetime"
          placement="bottom-start"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择开始时间">
        </el-date-picker>
        <!-- :picker-options="signOutStartTimeOption" -->
        <el-date-picker
          v-model="formData.signOutEndTime"
          placement="bottom-start"
          type="datetime"
          :disabled="isOutDisable"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择结束时间">
        </el-date-picker>
        <!-- :picker-options="signOutEndOption" -->
      </div>
    </div>
    <div class="wxq_submit" v-if="data.state == 9 || data.state == 3 || data.state == 0">
      <el-button class="btn btn_blue" @click="onsubmit">提交</el-button>
    </div>
  </div>
</template>

<script>
import API from "@/apis/backStageEndAPI/myCourseAPI";
export default {
  name: "signInAndOut",
  props: ['data'],
  data() {
    return {
      isSignDisable: false,
      isOutDisable: false,
      formData: {
        courseId: this.data.courseId,
        signInState: false,//签到状态
        signInStartTime: '',//签到开始时间
        signInEndTime: '',//签到结束时间
        signOutState: false,//签退状态
        signOutStartTime: '',//签退开始时间
        signOutEndTime: '',//签退结束时间
      },
      signInEndTimeOption: {
        disabledDate: time => {
          let signIn = this.formData.signInStartTime
          if (signIn) {
            return time.getTime() < new Date(signIn).getTime()//+ 8.64e7;
          }
        }
      },
      signOutStartTimeOption: {
        disabledDate: time => {
          let signOut = this.formData.signInEndTime
          if (signOut) {
            return time.getTime() < new Date(signOut).getTime() //+ 8.64e7;
          }
        },
      },
      signOutEndOption: {
        disabledDate: time => {
          let signOut = this.formData.signOutStartTime
          if (signOut) {
            return time.getTime() < new Date(signOut).getTime() //+ 8.64e7;
          }
        },
      },
    }
  },
  mounted() {
    this.formData.signInState = this.data.signInState == 0 ? false : true
    this.formData.signInStartTime = this.data.signInStartTime
    this.formData.signInEndTime = this.data.signInEndTime
    this.formData.signOutState = this.data.signOutState == 0 ? false : true
    this.formData.signOutStartTime = this.data.signOutStartTime
    this.formData.signOutEndTime = this.data.signOutEndTime
    if(this.formData.signInState == false) {
      this.isSignDisable = true
    }
    if(this.formData.signOutState == false) {
      this.isOutDisable = true
    }

  },
  methods: {
    onsubmit() { //提交
      API.updateCourse(this.formData).then(res => {
      })
    },
    changeSignIn(val) {
      if(val) {
        this.isSignDisable = false
      }else {
        this.isSignDisable = true
      }
    },
    changeSignOut(val) {
      if(val) {
        this.isOutDisable = false
      }else {
        this.isOutDisable = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wxq_table {
  width: 850px;
  height: 65px;
  background: #FFFFFF;
  border-radius: 12px;
  margin-top: 10px;
  padding-right: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    width: 200px;
    text-align: center;
    color: #606266;
  }
}
::v-deep .el-input {
  width: 192px;
  margin-right: 20px;
  .el-input__inner {
    border-radius: 8px;
  }
}
.wxq_submit {
  width: 850px;
  margin-top: 50px;
  text-align: center;
}
</style>
